<footer class="footer">
  <div class="footer-content">
    <div class="footer-left">
      <ng-container *ngFor="let item of footerData">
        <a [href]="item.url">
          {{ item.link_title }}
        </a>
      </ng-container>
    </div>
    <div class="footer-right">
      <!-- <ng-container *ngFor="let item of footerData">
        <p class="copyright" *ngIf="item.link_title.includes('©2019')">
          <span>{{ getTheCurrentYear() }}. {{ item.link_title.split('. ')[1] }}</span><br>
          {{ item.link_title.split('. ').slice(2).join('. ') }}
        </p>
      </ng-container> -->

      <img src="/assets/images/logoFooter.png" alt="Logo">
    </div>
  </div>
</footer>
