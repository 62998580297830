import { Component,OnInit } from '@angular/core';
import { GlobalService } from '../../legacy/providers/global.service';
import { CommonModule } from '@angular/common';



@Component({
  selector: 'app-pre-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pre-footer.component.html',
  styleUrl: './pre-footer.component.css'
})
export class PreFooterComponent implements OnInit {
  public footerMenu: any[] = [];
  public socialMediaLinks: any[] = [];

  constructor(private globalService: GlobalService) {}

  ngOnInit(): void {
    this.loadFooterMenu();
    this.loadSocialMediaLinks();
  }

  private loadFooterMenu(): void {
    this.globalService.getFooterMenu().subscribe((data: any) => {
      if (data && data.success) {
        this.footerMenu = data.data;
      }
    });
  }

  private loadSocialMediaLinks(): void {
    this.globalService.getSocialMediaLinks().subscribe((data: any) => {
      if (data && data.success) {
        this.socialMediaLinks = data.data;
        this.socialMediaLinks.sort((a, b) => {
          if (a.title === 'Linkedin') return -1;
          if (b.title === 'Linkedin') return 1;
          if (a.title === 'Facebook') return -1;
          if (b.title === 'Facebook') return 1;
          return 0;
        });
      }
    });
  }
}
