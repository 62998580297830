import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewContainerRef,
  ElementRef,
  HostListener,
  ViewChild,
  Renderer2,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterModule, NavigationEnd } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { CallUsInfo } from '../../models/callUs';
import { ContactInfo } from '../../models/contact';
import { GlobalService } from '../../legacy/providers/global.service';
import { filter } from 'rxjs/operators';

interface HeaderData {
  title: string;
  url: string;
  subtree: any[];
}

@Component({
  standalone: true,
  selector: 'app-header',
  imports: [CommonModule, RouterModule, FormsModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  subnav: boolean = false;
  searchQuery: string = '';
  isSearchVisible: boolean = false;
  menuValue: boolean = false;
  menu_icon: string = 'assets/images/fi-rr-align-justify.svg';
  isDropdownOpen: boolean = false;
  espaceSante = {
    url: 'https://sante.lamarocainevie.com/',
    link_title: 'Votre Espace Santé',
  };
  subMenu = { name: '', url: '', childs: [] };
  mainFooter = { url: '', link_title: '' };
  activeMenu: string = '';
  mDataSubmenu: any = null;
  // activeLink: [];
  public isDefaultMenuActive: boolean = false;
  isActive: boolean = false;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('searchIcon') searchIcon: ElementRef;
  @ViewChild('searchForm') searchForm: ElementRef;
  @ViewChild('subnavContainer') subnavContainer: ElementRef;
  showSearchInput: boolean = false;
  submenuVisible: boolean = true;
  showSubMenu = true;
  showIconsearch = true;
  activeLink: string = '';
  // DEBUT : variables utilisés par la 1 ere version du code
  public valueSearchM: string = '';
  public valueSearch: string = '';
  public mFormGroupContact!: FormGroup;
  public mFormGroupCall!: FormGroup;
  public formSubmitAttemptCall: boolean = false;
  public formSubmitAttemptContact: boolean = false;
  public showMessage: boolean = false;

  public headerClass = '';
  public showCallMe: boolean = false;
  public showContact: boolean = false;
  public showKnowMore: boolean = false;
  public showSearch: boolean = false;
  public showLang: boolean = false;
  public popup_title: string = '';
  public searchClasses = { 'ico-search': true, close: false };
  public langClasses = { 'ico-world': true, close: false };
  public today = new Date();
  // public call = 8 <= this.today.getHours() && this.today.getHours() < 16;
  public call = true;
  public mData: any = {
    logo_header: [],
    main_header: [],
    main_footer: [],
    social_Media: [],
    //add subemu
    submenu: [],
    phone: '',
    address: '',
    langue: [],
  };

  public logo: any;

  public contactInfo: ContactInfo;
  public callUsInfo: CallUsInfo;
  public langCode = '';
  public ContactPopupMsg: string = '';
  public isAccueilImgShow: boolean = true;
  public accueilUrl: string = '';
  public openMobileHeaderLink: boolean[] = [];
  public isMobileLinkHaveSubmenu: boolean[] = [];

  public headerData: HeaderData[] = [
    {
      title: 'Particuliers',
      url: '/particulier',
      subtree: [
        {
          title: 'Accueil',
          url: '',
          subtree: [],
        },
        {
          title: 'Épargne',
          url: '/particulier/epargne',
          subtree: [],
        },
        {
          title: 'Prévoyance',
          url: '/particulier/prevoyance',
          subtree: [],
        },
        {
          title: 'Retraite',
          url: '/particulier/retraite',
          subtree: [],
        },
        {
          title: 'Santé',
          url: '/particulier/sante',
          subtree: [],
        },
      ],
    },
    {
      title: 'Entreprises',
      url: '/entreprise',
      subtree: [
        {
          title: 'Accueil',
          url: '/entreprise',
          subtree: [],
        },
        {
          title: 'Nos produits',
          url: '/entreprise/produits',
          subtree: [],
        },
      ],
    },
    {
      title: 'Nous connaître',
      url: '/nous-connaitre/qui-sommes-nous',
      subtree: [
        {
          title: 'Qui sommes nous',
          url: '/nous-connaitre/qui-sommes-nous',
          subtree: [],
        },
        {
          title: 'Organigramme',
          url: '/nous-connaitre/organigramme',
          subtree: [],
        },
      ],
    },
    {
      title: 'Nous rejoindre',
      url: '/nous-connaitre/nous-rejoindre',
      subtree: [],
    },
    {
      title: 'Actualités',
      url: '/actualites',
      subtree: [],
    },
    {
      title: 'Documents utiles',
      url: '/documents-utiles',
      subtree: [],
    },
  ];

  //FIN

  private globalClickListener: () => void;

  constructor(
    private globalService: GlobalService,
    private mFormBuilder: FormBuilder,
    /*toasterService: ToasterService*/
    private router: Router,
    private renderer: Renderer2,
    private route: ActivatedRoute /*public toastr: ToastsManager,*/,
    vRef: ViewContainerRef
  ) {
    // this.toastr.setRootViewContainerRef(vRef);

    this.contactInfo = {
      name: '',
      from: '',
      tel: '',
      object: '',
      message: '',
      created: '',
      date: '',
    };

    this.callUsInfo = {
      full_name: '',
      tel: '',
    };

    // this.toasterService = toasterService;
  }
  isHomeActive: boolean;

  isObjectEmpty(objectName: any) {
    return (
      objectName &&
      Object.keys(objectName).length === 0 &&
      objectName.constructor === Object
    );
  }
  checkObjectIsEmpty(myObject: any) {
    return (
      this.isObjectEmpty(myObject) != false &&
      this.isObjectEmpty(myObject) != null &&
      this.isObjectEmpty(myObject) != undefined
    );
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedInsideSearchInput = this.searchInput?.nativeElement.contains(
      event.target
    );
    const clickedInsideSearchIcon = this.searchIcon?.nativeElement.contains(
      event.target
    );
    const clickedInsideSearchForm = this.searchForm?.nativeElement.contains(
      event.target
    );

    if (
      !clickedInsideSearchInput &&
      !clickedInsideSearchIcon &&
      !clickedInsideSearchForm
    ) {
      this.isSearchVisible = false;
      this.showSubMenu = true;
    }
  }

  allowedUrl(url: string | undefined): boolean {
    return url != '/espace-client' && url != '';
  }
  hasSubmenu(menuUrl: string): boolean {
    return this.mData.submenu.some((submenu) => submenu.url === menuUrl);
  }

  getSubmenu(menuUrl: string) {
    const submenu = this.mData.submenu.find((sub) => sub.url === menuUrl);
    return submenu ? submenu.childs : [];
  }

  hiddenSubMenuPages: string[] = [
    'contact',
    'actualites',
    'news-details/:id',
    'documents-utiles',
    'mention',
    'nous-connaitre/nous-rejoindre',
    'cgu',
    'confidentialite',
  ];
  isSearchIconVisible: boolean = true;
  isSubMenuVisible: boolean = true;

  ngOnInit(): void {
    this.updateVisibilityBasedOnRoute();
    this.initializeRouter();
    this.initializeSubmenuData();
    this.setupGlobalClickListener();
    this.loadStructureData();
    this.initializeLanguage();
    this.initializeForms();
    this.checkDefaultMenu();
  }

  private initializeRouter(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentUrl = this.router.url;
        this.isHomeActive = currentUrl === this.accueilUrl;
        this.updateVisibilityBasedOnRoute();
      });
  }

  private initializeSubmenuData(): void {
    const theCurrentUrl = this.router.url;
    let savedSubmenu = localStorage.getItem('lastDataSubmenu');
    console.log('theCurrentUrl: ', theCurrentUrl);
    console.log('savedSubmenu: ', savedSubmenu);
    const getCurrentUrlHeaderData = this.headerData.find(
      (e) => e.url === theCurrentUrl
    );

    console.log('getCurrentUrlHeaderData: ', getCurrentUrlHeaderData);

    if (
      getCurrentUrlHeaderData &&
      !(JSON.stringify(getCurrentUrlHeaderData?.subtree) === savedSubmenu)
    ) {
      localStorage.setItem(
        'lastDataSubmenu',
        JSON.stringify(getCurrentUrlHeaderData?.subtree)
      );
      savedSubmenu = JSON.stringify(getCurrentUrlHeaderData?.subtree);
    }
    if (savedSubmenu && theCurrentUrl !== '/') {
      this.mDataSubmenu = JSON.parse(savedSubmenu);
      const accueilItem = this.mDataSubmenu.find((e) => e.title === 'Accueil');
      this.accueilUrl = accueilItem?.url || '';
      this.isAccueilImgShow =
        !!accueilItem && accueilItem.url === this.accueilUrl;
      this.isHomeActive = this.router.url === this.accueilUrl;
    } else {
      this.loadHeaderData();
    }
  }

  private setupGlobalClickListener(): void {
    this.globalClickListener = this.renderer.listen(
      'document',
      'click',
      (event: Event) => {
        const clickedInsideSearch =
          this.searchInput?.nativeElement?.contains(event.target) ||
          this.searchIcon?.nativeElement?.contains(event.target) ||
          this.searchForm?.nativeElement?.contains(event.target);

        if (!clickedInsideSearch) {
          this.isSearchVisible = false;
          this.submenuVisible = true;
          this.showIconsearch = true;
        }
      }
    );
  }

  private initializeLanguage(): void {
    const [langCode] = window.navigator.language.split('-');
    this.langCode = langCode;
  }

  private initializeForms(): void {
    this.createContactForm();
    this.createCallForm();
  }

  loadHeaderData() {
    this.openMobileHeaderLink = Array(this.headerData.length).fill(false);
    this.isMobileLinkHaveSubmenu = this.headerData?.map(
      (e) => e.subtree.length > 0
    );
    localStorage.setItem(
      'isMobileLinkHaveSubmenu',
      JSON.stringify(this.isMobileLinkHaveSubmenu)
    );
    localStorage.setItem(
      'MobileHeaderLink',
      JSON.stringify(this.openMobileHeaderLink)
    );
    if (!this.mDataSubmenu) {
      this.mDataSubmenu = this.headerData[0].subtree;
      localStorage.setItem(
        'lastDataSubmenu',
        JSON.stringify(this.headerData[0].subtree)
      );
      this.isAccueilImgShow = this.headerData[0].subtree.some(
        (e) => e.title === 'Accueil'
      );
      this.accueilUrl = this.headerData[0].subtree?.find(
        (e) => e.title === 'Accueil'
      ).url;
      const currentUrl = this.router.url;
      this.isHomeActive = currentUrl == this.accueilUrl;
    }
  }

  loadStructureData() {
    this.globalService.getStructure().subscribe((data: any) => {
      if (!this.checkObjectIsEmpty(data)) {
        this.mData = data;
      }
      if (data && data.submenu) {
        this.mData = data;
        localStorage.setItem('dataStructure', JSON.stringify(data));
      }
    });
  }

  isActiveLink(url: string): boolean {
    const currentUrl = this.router.url;
    if (currentUrl === '/') {
      return url === '/';
    }
    if (
      (currentUrl == '/nous-connaitre/qui-sommes-nous' ||
        currentUrl == '/nous-connaitre/organigramme') &&
      url == '/nous-connaitre/qui-sommes-nous'
    ) {
      return true;
    }
    if (url === '/actualites' && currentUrl.startsWith('/news-details/')) {
      return true;
    }
    return currentUrl.includes(url);
  }
  isShadowVisible: boolean = false;

  private updateVisibilityBasedOnRoute(): void {
    const currentUrl = this.router.url;

    if (this.hiddenSubMenuPages.some((path) => currentUrl.includes(path))) {
      localStorage.setItem('lastDataSubmenu', JSON.stringify([]));
      this.showSubMenu = false;
      this.showIconsearch = false;
      this.isSubMenuVisible = false;
      this.isSearchIconVisible = false;
      this.isShadowVisible = true;
    } else {
      this.showSubMenu = true;
      this.showIconsearch = true;
      this.isSubMenuVisible = true;
      this.isSearchIconVisible = true;
      this.isShadowVisible = false;
    }
  }

  // isHeaderActiveLink

  public urlClick(menu: any) {
    console.log('menu test:', menu);
    if (menu && menu.subtree) {
      localStorage.setItem('lastDataSubmenu', JSON.stringify(menu.subtree));
      this.mDataSubmenu = menu.subtree;
    } else if (menu.url === '') {
      this.router.navigate(['/']);
    } else {
      console.error('Invalid menu structure:', menu);
    }
  }

  checkDefaultMenu(): void {
    this.isDefaultMenuActive = this.router.url === '/';
  }
  public openMenu() {
    this.menuValue = !this.menuValue;
    this.menu_icon = this.menuValue ? 'bi bi-x' : 'bi bi-list';
  }
  closeMenu(menu: any) {
    if (Object.keys(menu).length !== 0) this.urlClick(menu);
    this.menuValue = false;
    this.menu_icon = 'assets/images/fi-rr-align-justify.svg';
  }

  onButtonClick(): void {
    console.log('Button clicked!');
    this.openMenu();
  }

  toggleMenu() {
    this.menuValue = !this.menuValue;
    this.menu_icon = this.menuValue
      ? 'assets/images/close.svg'
      : 'assets/images/fi-rr-align-justify.svg';

    const isMobileLinkHaveSubmenusaved = localStorage.getItem(
      'isMobileLinkHaveSubmenu'
    );
    if (isMobileLinkHaveSubmenusaved) {
      this.isMobileLinkHaveSubmenu = JSON.parse(isMobileLinkHaveSubmenusaved);
    }
    const savedMobileHeaderLink = localStorage.getItem('MobileHeaderLink');
    if (savedMobileHeaderLink) {
      this.openMobileHeaderLink = JSON.parse(savedMobileHeaderLink);
      this.openMobileHeaderLink = this.openMobileHeaderLink.map(
        (e) => (e = false)
      );
      localStorage.setItem(
        'MobileHeaderLink',
        JSON.stringify(this.openMobileHeaderLink)
      );
    }
  }

  // navigateToMainContent() {
  //   this.router.navigate(['/main-content']);
  //   this.closeMenu({});
  // }
  // navigateToFooter() {
  //   this.router.navigate(['/footer']);
  //   this.closeMenu({});
  // }
  // navigateToHelp() {
  //   this.router.navigate(['/help']);
  //   this.closeMenu({});
  // }
  // navigateToPrefooter() {
  //   this.router.navigate(['/prefooter']);
  //   this.closeMenu({});
  // }

  public toggleDropdown(menu: any, index: number) {
    // this.activeMenu = this.activeMenu === url ? '' : url;
    const savedMobileHeaderLink = localStorage.getItem('MobileHeaderLink');
    this.urlClick(menu);
    if (savedMobileHeaderLink) {
      this.openMobileHeaderLink = JSON.parse(savedMobileHeaderLink);
    }
    this.openMobileHeaderLink[index] = !this.openMobileHeaderLink[index];
    localStorage.setItem(
      'MobileHeaderLink',
      JSON.stringify(this.openMobileHeaderLink)
    );
  }

  toggleSearch() {
    this.isSearchVisible = !this.isSearchVisible;
    this.showSubMenu = !this.isSearchVisible;
    this.showIconsearch = this.isSearchVisible;
  }
  closeSearch() {
    this.isSearchVisible = false;
    this.submenuVisible = true;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isSearchVisible = false;
    this.updateVisibilityBasedOnRoute();
  }

  public submitSearch(): void {
    if (this.searchQuery.trim()) {
      const searchUrl = `/recherche/resultat/${encodeURIComponent(
        this.searchQuery.trim()
      )}`;
      this.router.navigateByUrl(searchUrl).then((success) => {
        if (success) {
          console.log(`Navigation réussie vers ${searchUrl}`);
        } else {
          console.error(`Échec de la navigation vers ${searchUrl}`);
        }
      });
      this.searchQuery = '';
      this.isSearchVisible = false;
    } else {
      console.warn('Requête de recherche vide');
    }
  }

  onSearchInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.searchQuery = inputElement.value;
  }

  //DEBUT: fonctions utilisés v1 code
  private createContactForm() {
    this.mFormGroupContact = this.mFormBuilder.group({
      name: ['', [Validators.required]],
      phone: [
        '',
        [Validators.required, Validators.pattern('^[0]+[5-7]+[0-9]{8}$')],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      message: ['', [Validators.required]],
      object: ['', [Validators.required]],
    });
  }
  private createCallForm() {
    this.mFormGroupCall = this.mFormBuilder.group({
      name: ['', [Validators.required]],
      phone: [
        '',
        [Validators.required, Validators.pattern('^[0]+[5-7]+[0-9]{8}$')],
      ],
    });
  }

  public contactUS() {
    this.formSubmitAttemptContact = true;
    if (this.mFormGroupContact?.valid) {
      this.contactInfo = {
        name: this.mFormGroupContact.get('name')?.value,
        from: this.mFormGroupContact.get('email')?.value,
        tel: this.mFormGroupContact.get('phone')?.value,
        object: this.mFormGroupContact.get('object')?.value,
        message: this.mFormGroupContact.get('message')?.value,
        created: '',
        date: '',
      };

      this.showMessage = true;
      this.globalService.contactUs(this.contactInfo).subscribe(
        (response: any) => {
          this.closeContactEventAfterSubmit();
        },
        (_) => {
          // this.toastr.error('Echec d\'envoi de message!', null, {toastLife: 1000});
        }
      );
    }
  }

  public callUs() {
    this.formSubmitAttemptCall = true;
    if (this.mFormGroupCall?.valid) {
      this.callUsInfo = {
        full_name: this.mFormGroupCall.get('name')?.value,
        tel: this.mFormGroupCall.get('phone')?.value,
      };

      this.showMessage = true;
      this.globalService.callUs(this.callUsInfo).subscribe(
        (response: any) => {
          //  this.toastr.success('Success!', null, {toastLife: 1000});
          this.closeContactEventAfterSubmit();
          this.callUsInfo.full_name = '';
          this.callUsInfo.tel = '';
          this.mFormGroupCall?.reset({ name: '', phone: '' });
          this.formSubmitAttemptCall = false;
        },
        (_) => {
          //  this.toastr.error('Error!', null, {toastLife: 1000});
        }
      );
    }
  }

  public goToSearch(value: any) {
    if (value !== null && value.trim() !== '') {
      this.showSearch = false;
      this.searchClasses.close = false;
      this.router.navigate(['/recherche/resultat/' + value]);
    }
  }

  getSubmenus(menu: any) {
    if (menu && menu.subtree) {
      localStorage.setItem('lastDataSubmenu', JSON.stringify(menu.subtree));
      this.mDataSubmenu = menu.subtree;
    } else {
      console.error('Invalid menu structure:', menu);
    }
    return this.mDataSubmenu;
  }

  public showCallMeEvent() {
    this.showCallMe = true;
    this.showContact = false;
    this.showSearch = false;
    this.showLang = false;
    this.langClasses.close = false;
    this.searchClasses.close = false;
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  public showContactEvent(object: any) {
    this.contactInfo.object = object;
    this.showContact = true;
    this.showCallMe = false;
    this.showSearch = false;
    this.showLang = false;
    this.langClasses.close = false;
    this.searchClasses.close = false;
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    return false;
  }

  public showKnowMoreEvent(title: string = '') {
    this.showKnowMore = true;
    this.showContact = false;
    this.showSearch = false;
    this.showLang = false;
    this.langClasses.close = false;
    this.popup_title = title;
    this.searchClasses.close = false;
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    return false;
  }

  public showSearchEvent(menuTitle: any) {
    if (menuTitle === 'recherche') {
      this.showCallMe = false;
      this.showContact = false;
      this.showLang = false;
      this.langClasses.close = false;
      this.showSearch = !this.searchClasses.close;
      this.searchClasses.close = !this.searchClasses.close;
    }
  }

  public showLangEvent(menuTitle: any) {
    if (menuTitle === 'lang') {
      if (
        !this.showCallMe &&
        !this.showContact &&
        !this.showSearch &&
        !this.showKnowMore
      ) {
        this.showLang = true;
        this.langClasses.close = true;
      }
    }
  }

  public closeLangEvent(menuTitle: any) {
    if (menuTitle === 'lang') {
      this.showLang = false;
      this.langClasses.close = false;
    }
  }

  public closeContactEventAfterSubmit() {
    setTimeout(() => {
      this.showContact = false;
      this.showCallMe = false;
      this.headerClass = '';
    }, 3000);
  }

  public closeContactEvent() {
    this.showContact = false;
    this.showCallMe = false;
    this.headerClass = '';
  }

  public closeKnowMoreEvent() {
    this.showKnowMore = false;
    this.headerClass = '';
  }

  public donePopupAnimation() {
    if (
      !this.showContact &&
      !this.showSearch &&
      !this.showLang &&
      !this.showCallMe &&
      !this.showKnowMore
    ) {
      this.headerClass = '';
    }
  }

  public startPopupAnimation() {
    if (
      this.showContact ||
      this.showSearch ||
      this.showCallMe ||
      this.showKnowMore
    ) {
      this.headerClass = 'dark';
    }
  }
  //FIN

  // goToContact() {
  //   this.router.navigate(['/contact']);
  // }

  ngOnDestroy() {
    if (this.globalClickListener) {
      this.globalClickListener();
    }
  }
}
