import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { GlobalService } from '../../legacy/providers/global.service';
import { FormBuilder } from '@angular/forms';
import { CallUsInfo } from '../../models/callUs';
import { ContactInfo } from '../../models/contact';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public footerData: any[] = [];

  public logo: any;

  public contactInfo: ContactInfo;
  public callUsInfo: CallUsInfo;
  mainFooter = { url: "", link_title: "" };

  constructor(private globalService: GlobalService, private mFormBuilder: FormBuilder,
    /*toasterService: ToasterService*/ private router: Router, private route: ActivatedRoute, /*public toastr: ToastsManager,*/
    vRef: ViewContainerRef) {

    // this.toastr.setRootViewContainerRef(vRef);

    this.contactInfo = {
      name: '',
      from: '',
      tel: '',
      object: '',
      message: '',
      created: '',
      date: ''
    };

    this.callUsInfo = {
      full_name: '',
      tel: ''
    };

    // this.toasterService = toasterService;
  }

  ngOnInit(): void {
    this.globalService.getStructure().subscribe((data: any) => {
      if (data && data.main_footer) {
        this.footerData = data.main_footer.sort((a,b) => a.order - b.order);
        // this.footerData = this.reorderFooterLinks(this.footerData);
        console.log("Footer Data:", this.footerData);
      }
    });
  }
//   reorderFooterLinks(footerLinks: any[]): any[] {
//     const firstLinks = ['Nous rejoindre', 'Contactez-nous'];
//     const lastLinks = ['Mentions légales', 'Conditions Générales d\'Utilisation', 'Politique de confidentialité'];
//     const first = footerLinks.filter(item => firstLinks.some(title => item.link_title.toLowerCase().includes(title.toLowerCase())));
//     const last = footerLinks.filter(item => lastLinks.some(title => item.link_title.toLowerCase().includes(title.toLowerCase())));
//     const others = footerLinks.filter(item => 
//         !firstLinks.some(title => item.link_title.toLowerCase().includes(title.toLowerCase())) &&
//         !lastLinks.some(title => item.link_title.toLowerCase().includes(title.toLowerCase()))
//     );

//     return [...first, ...others, ...last];
// }

  isObjectEmpty(objectName: any) {
    return (
      objectName &&
      Object.keys(objectName).length === 0 &&
      objectName.constructor === Object
    );
  }
  checkObjectIsEmpty(myObject: any) {
    return this.isObjectEmpty(myObject) != false && this.isObjectEmpty(myObject) != null && this.isObjectEmpty(myObject) != undefined
  }
  allowedUrl(url: string) {
    return url != "/espace-client" && url != "";
  }

  getTheCurrentYear() {
    return new Date().getFullYear();
  }
}


