<div class="header-container">
  <div class="header-top-bottom"  [ngClass]="{'shadow-button': isShadowVisible}">

    <div class="header-top d-flex justify-content-between align-items-center">
      <a *ngFor="let logo of mData.logo_header" [routerLink]="[logo.url]" class="brand-logo"
        [style.backgroundImage]="'url(' + logo.image + ')'">
      </a>
      <div class="contact-button">
        <a class="btn btn-outline-secondary rounded-pill px-3 py-2" style="color: black;" href="/contact">
          Contactez-nous
          <img src="/assets/images/casque.svg" alt="Contact">
        </a>
        <!-- <a href="{{ espaceSante.url }}" class="btn btn-danger rounded-pill px-3 py-2"
          style="background-color: rgba(230, 0, 40, 1);" target="_blank" rel="noopener noreferrer">
          {{ espaceSante.link_title }}
          <img src="/assets/images/fi-rr-heart.svg" alt="Espace Santé">
        </a> -->
      </div>
      <button type="button" class="menu-icon d-md-none" (click)="toggleMenu()">
        <img [src]="menu_icon" class="menu-icon-img" alt="Menu">
      </button>
    </div>

    <div class="header-bottom">
      <ul class="nav nav-pills">
        <ng-container *ngFor="let menu of headerData">
          <li *ngIf="allowedUrl(menu?.url)" class="nav-item">
            <a class="nav-link" [routerLink]="menu?.url" [class.active]="isActiveLink(menu.url)"
              (click)="urlClick(menu)">{{ menu.title }}</a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <div class="submenu-container" [hidden]="isSearchVisible || !showSubMenu || mDataSubmenu.length == 0">
    <div class="submenu-body">
      <ng-container>
        <div class="header-search">
          <div class="sub-nav">
            <ul>
              <li class="nav-item" *ngIf="isAccueilImgShow">
                <a class="nav-link" [routerLink]="accueilUrl" routerLinkActive="home-active">
                  <img [src]="isHomeActive ? '/assets/images/fi-rr-home.svg' : 'assets/images/fi-rr-home (1).svg'"
                    alt="Home" class="home-icon">
                </a>
              </li>
              <ng-container *ngFor="let menu of mDataSubmenu">
                <li class="nav-item dropdown" *ngIf="menu.title !== 'Accueil'">
                  <a class="nav-link" [routerLink]="menu?.url" [routerLinkActive]="'active'"
                    [routerLinkActiveOptions]="{ exact: true }">{{ menu?.title }}</a>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </ng-container>
      <a *ngIf="showIconsearch" class="search-container d-flex align-items-center" #searchIcon (click)="toggleSearch()">
        <img class="search-icon" src="/assets/images/Vector (6).svg" alt="Search Icon" />
      </a>
    </div>
  </div>

  <div class="search-form" *ngIf="isSearchVisible" #searchForm>
    <div class="search-container-form">
      <input type="text" class="form-control" placeholder="Rechercher" [(ngModel)]="searchQuery"
        (keyup.enter)="submitSearch()" (input)="onSearchInputChange($event)" #searchInput>
      <a *ngIf="searchQuery.length > 0" class="btn" [ngClass]="{'search-icon-active': searchQuery.length > 0}" (click)="submitSearch()">
        <img src="assets/images/fi-rr-arrow-small-right.svg">
      </a>
      <a *ngIf="searchQuery.length == 0" class="btn" [ngClass]="{'search-icon-active': searchQuery.length > 0}" (click)="toggleSearch()">
        <img src="assets/images/close-search.svg">
      </a>
      <!-- <a *ngIf="searchQuery.length == 0" class="btn" (click)="toggleSearch()">
        <img [src]="searchQuery.length > 0 ? 'assets/images/fi-rr-arrow-small-right.svg' : '/assets/images/close-search.svg'">
      </a> -->
    </div>
  </div>


  <div class="mobile-menu d-md-none" [class.active]="menuValue">
    <div class="mobile-menu-header">
      <img src="/assets/images/Logogris.png" alt="Logo" class="logo-mobile">
      <button type="button" class="close-menu" (click)="closeMenu({})">
        <img src="/assets/images/close.svg" alt="Close">
      </button>
    </div>
    <div class="nav-links">
      <ng-container *ngFor="let menu of headerData; let i = index">
        <div class="dropdown">
          <div class="btn btn-secondary "
            style="color: black; background-color: white;display: flex;align-items: center; justify-content: space-between; border:none; height: 50px;">
            <a [routerLink]="!isMobileLinkHaveSubmenu[i] ? menu.url : null"
              [attr.href]="isMobileLinkHaveSubmenu[i] ? 'javascript:void(0);' : null"
              style="text-decoration: none; color: black;">
              {{ menu.title }}
            </a>
            <a *ngIf="isMobileLinkHaveSubmenu[i]" (click)="toggleDropdown(menu, i)">
              <img [src]="openMobileHeaderLink[i] ? 'assets/images/down-icon.svg' : 'assets/images/drop-icon.svg'"
                [alt]="openMobileHeaderLink[i] ? 'down-image' : 'drop-image'" />
            </a>
          </div>
          <div *ngIf="openMobileHeaderLink[i]" class="dropdown-menu">
            <ng-container *ngFor="let sub of getSubmenus(menu)">
              <a class="dropdown-item" [routerLink]="sub.url" (click)="closeMenu(menu)">
                {{ sub.title }}
              </a>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="contact-buttons">
      <a class="btn btn-outline-secondary rounded-pill px-3 py-2 contact-btn" style="color: black;"
        href="/contact">
        Contactez-nous
        <img src="/assets/images/casque.svg" alt="Contact">
      </a>
      <!-- <a href="{{ espaceSante.url }}" class="btn btn-danger rounded-pill px-3 py-2 contact-btn"
        style="background-color: rgba(230, 0, 40, 1);">
        {{ espaceSante.link_title }}
        <img src="/assets/images/fi-rr-heart.svg" alt="Espace Santé" loading="lazy">
      </a> -->
    </div>
  </div>
</div>
