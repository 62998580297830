<div class="pre-footer">
  <div class="sub-pre-footer">
    <div class="pre-footer-column" *ngFor="let section of footerMenu">
      <h3>{{ section.title }}</h3>
      <ul>
        <li *ngFor="let item of section.subtree">
          <a href="{{item.url}}">{{ item.title }}</a>
          <ul *ngIf="item.subtree.length > 0">
            <li *ngFor="let subItem of item.subtree">
              <a href="{{subItem.url}}">{{ subItem.title }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="pre-footer-column">
      <h3>NOUS REJOINDRE</h3>
      <div class="social-links">
        <a *ngFor="let link of socialMediaLinks" [href]="link.url" target="_blank" rel="noopener noreferrer">
          <img *ngIf="link.title === 'Linkedin'" src="/assets/images/Vector (3).svg" alt="LinkedIn">
          <!-- <img *ngIf="link.title === 'Facebook'" src="/assets/images/Container.svg" alt="Facebook"> -->
        </a>
      </div>
    </div>
  </div>
</div>
